import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWechatTitle from 'vue-wechat-title';
import '@/request/http'
import global from '@/components/global'
import {
    Button,
    Search,
    Icon,
    Stepper,
    NoticeBar,
    Tabbar,
    TabbarItem,
    Field,
    Divider,
    DatetimePicker,
    Popup,
    Picker,
    List,
    Cell,
    Checkbox,
    CheckboxGroup,
    CellGroup,
    Uploader,
    ImagePreview,
    Image as VanImage,
    Grid,
    GridItem,
    Switch,
    NumberKeyboard, RadioGroup, Radio,
    Form,
    Swipe,Tab, Tabs,Empty,Rate,Tag,Col, Row,NavBar,
    SwipeItem,ActionBar, ActionBarIcon, ActionBarButton,Badge,SubmitBar,PullRefresh
} from 'vant'

//
// import Vconsole from 'vconsole';
//
// let vConsole = new Vconsole();
//
// export default vConsole

const app = createApp(App)
app.use(store)
    .use(router)
    .use(NavBar)
    .use(Button)
    .use(Icon)
    .use(Stepper)
    .use(NoticeBar)
    .use(Button)
    .use(Search)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Swipe)
    .use(Switch )
    .use(SwipeItem)
    .use( RadioGroup)
    .use(Radio)
    .use(Field)
    .use(CellGroup)
    .use(Uploader)
    .use(VanImage)
    .use(Grid)
    .use(GridItem)
    .use(Divider)
    .use(DatetimePicker)
    .use(Popup)
    .use(Picker)
    .use(List)
    .use(Cell)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(NumberKeyboard)
    .use(Form)
    .use(Col)
    .use(Row)
    .use(ActionBar)
    .use(ActionBarIcon)
    .use(ActionBarButton)
    .use(Badge)
    .use(SubmitBar)
    .use(PullRefresh)
    .use(Tab)
    .use(Tabs)
    .use(Empty )
    .use(VueWechatTitle)
    .use(global)
    .use(Rate )
    .use(Tag  )
    .use(ImagePreview  )
    .mount('#app')
