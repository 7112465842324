/*
 * @Descripttion: 
 * @Author: ''
 * @Date: 2021-03-11 19:36:01
 * @LastEditors: ''
 * @LastEditTime: 2021-04-03 17:39:19
 */
let API = {
    getCarousel:"/getCarousel",
    boutiqueGoods:"/boutiqueGoods",
    recommendGoods:'/recommendGoods'
}
export default  API