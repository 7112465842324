
let envMap = {
    development:"LOC",
    DEV:'DEV',
    PRO:'PRO'
  }
const prefix = {
    LOC: "http://localhost:9001/api-user/bxt-user/", //本地（webpack配置了代理地址）
    DEV: 'http://55pnyw.natappfree.cc', //测试环境
    PRO: 'https://www.bxthr.com', //生产环境
  }

let env = envMap[process.env.NODE_ENV] || 'LOC'

const getBaseUrl = function (type) {
    
    const BASE = prefix[type];

    return {
      baseUrl: BASE + '/', // 基础接口地址
    }
  }

  let URL = {

    LOC:Object.assign(getBaseUrl('LOC'),{
      webpackUrl: prefix[env],
      baseUrl: "/",
    }),

    DEV:Object.assign(getBaseUrl('DEV'),{

    }),

    PRO:Object.assign(getBaseUrl('PRO'),{
        
    })
    
  }
  
  let config = {
    webpackUrl:URL[env].webpackUrl || "",
    baseUrl:URL[env].baseUrl,
  }

module.exports = config