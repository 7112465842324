/*
 * @Descripttion: 
 * @Author: ''
 * @Date: 2021-04-03 17:39:17
 * @LastEditors: ''
 * @LastEditTime: 2021-04-03 17:40:15
 */
let API = {
    getGoodsNum:"/getGoodsNum",
    getGoodsdetal:"/getGoodsdetal",
}
export default  API