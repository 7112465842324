/*
 * @Descripttion: 
 * @Author: ''
 * @Date: 2021-03-15 17:10:15
 * @LastEditors: ''
 * @LastEditTime: 2021-03-20 11:18:42
 */
let API = {
    classifyGoods:'/classifyGoods',
    getClassify:'/getClassify'
}
export default  API