import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    // {
    //     // 会匹配所有路径
    //     path: '*'
    // },
    {
        path: '/',
        redirect: '/GetUserInfo',
        name: 'App'
    },
    {
        path: '/GetUserInfo',
        name: 'GetUserInfo',
        meta: {
            title: '登录中...',
        },
        component: () => import( '@/GetUserInfo.vue')
    },
    {
        path: '/MyInfo',
        name: 'MyInfo',
        meta: {
            title: '个人中心',
        },
        component: () => import( '@/views/MyInfo.vue')
    },
    {
        path: '/ChooseWorker',
        name: 'ChooseWorker',
        meta: {
            title: '服务中心',
            requireAuth: true
        },
        component: () => import( '@/views/ChooseWorker.vue')
    },
    {
        path: '/Register',
        name: 'Register',
        meta: {
            title: '注册',
        },
        component: () => import( '@/views/Register.vue')
    },
    {
        path: '/ServiceCenter',
        name: 'Home',
        meta: {
            title: '服务中心',
            // requireAuth: true

        },
        component: () => import( '@/views/ServiceCenter.vue')
    },
    {
        path: '/RegisterCompany',
        name: 'RegisterCompany',
        meta: {
            title: '注册',
        },
        component: () => import( '@/views/RegisterCompany.vue')
    },
    {
        path: '/RegisterProtocol',
        name: 'RegisterProtocol',
        meta: {
            title: '注册协议',
        },
        component: () => import( '@/views/RegisterProtocol.vue')
    },
    {
        path: '/RegisterSelect',
        name: 'RegisterSelect',
        meta: {
            title: '注册',
        },
        component: () => import( '@/views/RegisterSelect.vue')
    }, {
        path: '/VipPay',
        name: 'VipPay',
        meta: {
            title: '购买会员',
        },
        component: () => import( '@/views/VipPay.vue')
    },
    {
        path: '/Result',
        name: 'Result',
        meta: {
            title: '结果',
            requireAuth: true
        },
        component: () => import( '@/views/Result.vue')
    },
    {
        path: '/OrderList',
        name: 'OrderList',
        meta: {
            title: '全部订单',
            requireAuth: true

        },
        component: () => import( '@/views/OrderList.vue'),
        // children: [{
        //     path: 'Test/:active',
        //     name: 'post',
        //     component: () => import( '@/views/OrderList.vue'),
        // }],
    },
    {
        path: '/OrderDetail/:orderId',
        name: 'OrderDetail',
        meta: {
            title: '订单详情',
            requireAuth: true
        },
        component: () => import( '@/views/OrderDetail.vue')
    },
    {
        path: '/OrderEvaluate/:orderId',
        name: 'OrderEvaluate',
        meta: {
            title: '评价',
            requireAuth: true
        },
        component: () => import( '@/views/OrderEvaluate.vue')
    },
    {
        path: '/PersonalInfo',
        name: 'PersonalInfo',
        meta: {
            title: '个人信息',
            requireAuth: true
        },
        component: () => import( '@/views/PersonalInfo.vue')
    },
    {
        path: '/CompanyExplain',
        name: 'CompanyExplain',
        meta: {
            title: '公司简介',
            // requireAuth: true
        },
        component: () => import( '@/views/CompanyExplain.vue')
    },
    {
        path: '/VipProtocol',
        name: 'VipProtocol',
        meta: {
            title: '会议协议',
            // requireAuth: true
        },
        component: () => import( '@/views/VipProtocol.vue')
    }, {
        path: '/ServiceProtocol',
        name: 'ServiceProtocol',
        meta: {
            title: '服务协议',
            // requireAuth: true
        },
        component: () => import( '@/views/ServiceProtocol.vue')
    }, {
        path: '/ChangeOrderStatus',
        name: 'ChangeOrderStatus',
        meta: {
            title: '接单状态',
            requireAuth: true
        },
        component: () => import( '@/views/ChangeOrderStatus.vue')
    },
    {
        path: '/PublishJob',
        name: 'PublishJob',
        meta: {
            title: '发布招工',
            // requireAuth: true
        },
        component: () => import( '@/views/PublishJob.vue')
    },
    {
        path: '/PublishPersonInfo',
        name: 'PublishPersonInfo',
        meta: {
            title: '发布找活',
            // requireAuth: true
        },
        component: () => import( '@/views/PublishPersonInfo.vue')
    },
    {
        path: '/CancelOrder/:orderId',
        name: 'CancelOrder',
        meta: {
            title: '取消订单',
            requireAuth: true
        },
        component: () => import( '@/views/CancelOrder.vue')
    }, {
        path: '/CancelAccount',
        name: 'CancelAccount',
        meta: {
            title: '注销账号',
            requireAuth: true
        },
        component: () => import( '@/views/CancelAccount.vue')
    },
    {
        path: '/404',
        name: '404',
        meta: {
            title: '页面未找到',
        },
        component: () => import( '@/404.vue')
    },
    {
        path: '/Test',
        name: 'Test',
        meta: {
            title: '服务中心',
        },
        component: () => import( '@/views/Test.vue')
    },
    {
        path: '/JobDetail/:jobId',
        name: 'JobDetail',
        meta: {
            title: '服务中心',
            requireAuth: true
        },
        component: () => import( '@/views/JobDetail.vue')
    },
    {
        path: '/PublishedPersonInfoDetail/:workerId',
        name: 'PublishedPersonInfoDetail',
        meta: {
            title: '服务中心',
            requireAuth: true
        },
        component: () => import( '@/views/PublishedPersonInfoDetail.vue')
    },
    {
        path: '/Search',
        name: 'Search',
        meta: {
            title: '搜索',
        },
        component: () => import( '@/views/Search.vue')
    },
    {
        path: '/CompleteWorker',
        name: 'CompleteWorker',
        meta: {
            title: '注册',
            // requireAuth: true
        },
        component: () => import( '@/views/CompleteWorker.vue')
    },
    // {
    //     path: '/Test5/:id',
    //     name: 'Test5',
    //     meta: {
    //         title: '服务中心',
    //     },
    //     component: Test2,
    //     // children:[{
    //     //     path: 'post',
    //     //     name: 'post',
    //     //     component: Test2,
    //     // }],
    // },
    // // {path: '/:pathMatch(.*)', redirect: '/404'},
]

const router = createRouter({
    // history: createWebHistory(process.env.VUE_APP_ROUTER),
    history: createWebHistory('/'),
    // history: createWebHashHistory(),
    routes: routes,

})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // 不存在的路由跳转404
    if (!to.matched.length) {
        next({path: '404'})
        return;
    }
    let token = sessionStorage.getItem("token");
    let openid = localStorage.getItem("openid")
    let userinfo = localStorage.getItem("userinfo")
    if (to.meta.requireAuth) {
        if (!token) {
            if (!openid) {
                if (to.name == 'GetUserInfo') {
                    next()
                } else {
                    next({path: '/GetUserInfo', query: {jumpUrl: to.path}})
                }
            } else {
                if (to.name == 'GetUserInfo') {
                    next()
                } else {
                    if (userinfo) {
                        next({path: '/GetUserInfo', query: {jumpUrl: to.path}})
                    } else {
                        if (to.name == 'Home') {
                            next()
                        } else {
                            next({path: '/RegisterSelect'})
                        }

                    }
                }

            }
        } else {
            next()
        }
    } else {
        next()
    }
    // if (to.meta.requireAuth) {
    //     let token = sessionStorage.getItem("token")
    //     let openid = localStorage.getItem("openid")
    //     let userinfo = localStorage.getItem("userinfo")
    //     // 本地是否有用户信息
    //     // 有直接跳转首页
    //     //没有则跳转登录页面
    //
    //     if (!token) {
    //         console.log('to', to.name)
    //         if (to.name == 'GetUserInfo') {
    //             next()
    //         } else
    //         {
    //             if (to.name.toLowerCase().indexOf('register') != -1 && openid) {
    //                 next()
    //             } else {
    //                 next({path: '/GetUserInfo', query: {jumpUrl: to.path}})
    //             }
    //         }
    //     } else {
    //         if (to.name.toLowerCase().indexOf('register') != -1) {
    //             next({path: from.path})
    //         } else {
    //             next()
    //         }
    //     }
    // } else {
    //
    //     next()
    // }
})

export default router
