<!--
 * @Descripttion: 
 * @Author: ''
 * @Date: 2021-04-01 17:53:22
 * @LastEditors: ''
 * @LastEditTime: 2021-04-01 17:54:12
-->
<!--
 * @Descripttion: 
 * @Author: ''
 * @Date: 2021-03-31 09:51:18
 * @LastEditors: ''
 * @LastEditTime: 2021-04-01 14:16:23
-->

<template>
  <div>
      test
  </div>
</template>

<script>

export default {
  name: 'testGlobal',
  props:{
   
  },
  setup(){
   
    
    return {
        
    }
  }
}
</script>
