<!--
 * @Descripttion: 
 * @Author: ''
 * @Date: 2021-03-05 15:26:44
 * @LastEditors: ''
 * @LastEditTime: 2021-04-03 18:30:02
-->
<template>
<!--  <router-view v-slot="{ Component }">-->
<!--    <keep-alive include="Shop">-->
<!--      <component :is="Component" />-->
<!--    </keep-alive>-->
<!--  </router-view>-->
  <router-view v-wechat-title="$route.meta.title"></router-view>
</template>

<script>
import '@/assets/css/index.less'
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import util from '@/util/util'
export default {
  name: 'App',
  components: {},
  setup(){
    let methodsMap = {
    }
    onMounted(()=>{
    })
    return {
      ...methodsMap
    }
  }
}
</script>
