/*
 * @Descripttion: 
 * @Author: ''
 * @Date: 2021-03-20 10:35:42
 * @LastEditors: ''
 * @LastEditTime: 2021-04-03 14:43:36
 */
let API = {
    getCard:"/getCard",
    delGoods:'/delCard',
    changeGoods:'/changeGoods'
}
export default  API