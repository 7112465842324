/*
 * @Descripttion: 
 * @Author: ''
 * @Date: 2021-03-11 19:13:11
 * @LastEditors: ''
 * @LastEditTime: 2021-04-03 17:08:43
 */
import axios from 'axios'
import {Dialog, Toast} from 'vant';
import router from "../router";
import subcribe from "../util/subcrible"
// 超时时间
axios.defaults.timeout = 50000;
// http请求拦截器
axios.interceptors.request.use(config => { //请求前的处理
    // Toast.loading({
    //     message: '加载中...',
    //     forbidClick: true,
    // });
    let token = sessionStorage.getItem("token")
    if (token) {
        config.headers["X-Access-Token"] = token
    }
    if (config.addHeaders) {
        Object.assign(config.headers, config.addHeaders)
    }
    let userinfo = JSON.parse(localStorage.getItem("userinfo"))
    if (userinfo && userinfo.uid) {
        config.headers["uid"] = userinfo.uid
    }
    return config;

}, error => {
    Toast.clear();
    return Promise.reject(error)
})

//http响应拦截器
axios.interceptors.response.use(data => { //请求后的处理
    return data
}, error => {
    let res = {
        code: -1,
        msg: '网络断开了，请检查网络状况。'
    }
    if (error.response) {
        console.log(error.response)

        if (error.response.data) {
            if (error.response.data.code == 9999) {
                // subcribe.alterSubscribe()
                Dialog({
                    title:'请关注公众号',
                    message: `<div style="text-align: center;height: 220px;overflow: hidden">
  <img width="100%" height="100%" src="https://www.bxthr.com/storage/qrcode/qrcode.jpg" />
                              </div>`,
                    allowHtml: true,
                });
            } else {
                Toast(error.response.data.msg ? error.response.data.msg : '系统繁忙,请再次尝试！');
            }
        } else {
            Toast('系统繁忙,请再次尝试！');
        }

        // token 过期重新加载
        // if (error.response.status == 401) {
        //     router.replace({
        //         path: 'GetUserInfo',
        //         query: {jumpUrl: router.currentRoute.value.path}
        //     })
        // } else {
        //     if (error.response.data) {
        //         Toast(error.response.data.msg ? error.response.data.msg : '系统繁忙,请再次尝试！');
        //     } else {
        //         Toast('系统繁忙,请再次尝试！');
        //     }
        // }
        return Promise.reject(error.response.data)
    }
    Toast.fail(res.msg);
    return Promise.reject(res)
})

export default axios